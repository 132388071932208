.public-DraftStyleDefault-block {
    margin: 0;
}

.rdw-link-modal {
    height: 235px !important;
}

.rdw-link-decorator-wrapper {
    text-decoration: underline;
}